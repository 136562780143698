import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from '@hookform/resolvers/zod';
import { validateVAT } from "./VATField";
import Settings from "../settings";
import { data_create_api_resource } from "../utils/http_functions";

import { 
  TextField, FormControlLabel, Checkbox, 
  FormHelperText, Grid, Box, Link, 
  styled
} from "@mui/material"
import { 
  RichContent, Button, RichHTML
} from "@gisce/oficina-virtual-components";
import { useTranslation } from "react-i18next";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useNavigate } from "react-router-dom";
import { SignUpData } from "@/types/definitions/signup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { persistData } from "@/actions/signup";

type SignUpFormData = SignUpData;

const NoFlagMuiTelInputStyled = styled(MuiTelInput)`
.MuiTelInput-IconButton {
  display: none;
}
`;

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [backendErrors, setBackendErrors] = useState({});
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [successBody, setSuccessBody] = useState(null);
  const { t } = useTranslation();

  const signup = useSelector((state: RootState) => state.signup);

  const schema = z.object({
    contract: z.string().min(1, t('common:text.required_field')),
    nif: z.string().min(1, t('common:text.required_field'))
      .refine(
        nif => validateVAT(nif), 
        {message: t('common:text.vat_field_validation_invalid')}
      ),
    email: z.string()
      .min(1, t('common:text.required_field'))
      .email({ message: t('common:text.contractation_email_not_valid') }),
    email2: z.string()
      .min(1, t('common:text.required_field'))
      .email({ message: t('common:text.contractation_email_not_valid') }),
    ...(Settings.signUp?.phoneField &&
      {
        phone: z.string()
        .min(4, t('common:text.required_field'))
        .refine(
          (tel) => matchIsValidTel(tel), 
          {message: t('common:text.lead_contact_phone_not_valid')}
        ),
      }
    ),
    ...(Settings.signUp?.invoiceNumberField && 
      {
        invoiceNumber: z.string().min(1, t('common:text.required_field'))
      }
    ),
    acceptConditionsAndGdpr: z.boolean().refine((val) => val === true, {
      message: t('common:text.contractation_ov_validation'),
    }),
  }).superRefine((data, ctx) => {
    if (data.email !== data.email2) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Els correus no coincideixen',
        path: ["email2"],
      });
    }
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm<SignUpFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      nif: signup.nif,
      contract: signup.contract,
      email: signup.email,
      email2: signup.email2,
      invoiceNumber: signup.invoiceNumber,
      acceptConditionsAndGdpr: signup.acceptConditionsAndGdpr,
      phone: signup.phone
    }
  });

  // TODO: Revisar que funciona la resposta del formulari i pensar què fer quan està carregant

  // Debounce data
  useEffect(() => {
    const debounceForm = setTimeout(() => {
      if (JSON.stringify(watch()) !== JSON.stringify(signup)) {
        dispatch(persistData(watch()))
      }
    }, 1000);

    return () => clearTimeout(debounceForm);
  }, [...Object.values(watch())])

  const onSignUpSubmit = (values: SignUpFormData) => {
    values.nif = values.nif.replace('ES', '');

    const data = {
      FormConfirm: {
        nif: values.nif,
        contract: values.contract,
        email: values.email,
        email2: values.email2,
        accept_conditions: values.acceptConditionsAndGdpr,
        accept_gdpr: values.acceptConditionsAndGdpr,
        ...(Settings.signUp?.phoneField && {phone: values.phone}),
        ...(Settings.signUp?.invoiceNumberField && {invoiceNumber: values.invoiceNumber})
      },
    };

    setIsLoading(true);
    data_create_api_resource(null, "signup/confirm", data)
    .then(({data}) => {
      const r = data.result;
      if (r.render && Object.keys(r.render.errors).length) {
        console.log("Backend validation errors", r.render.errors);
        setBackendErrors(r.render.errors);
      } else {
        setSubmitSuccessful(true);
        setSuccessBody(r.render.body);
      }
    })
    .finally(() => {
      setIsLoading(false);
    });

  };

  const handleOnLoginNav = () => {
    navigate(t('common:url.login'));
  }

  return isLoading ? <div>Loading</div> : !submitSuccessful ? (
    <Box sx={{p: {xs: 0, sm:2}}}>
      <form onSubmit={handleSubmit(onSignUpSubmit)}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <TextField
              label={t('common:text.signup_field_nif')}
              {...register("nif")}
              error={!!(errors.nif)}
              style={{ width: "100%" }}
            />
            {!!(errors.nif?.message) &&
              <FormHelperText error={true}>
                {errors.nif.message}
              </FormHelperText>
            }
          </Grid>
        
          <Grid item xs={12}>
            <TextField
              label={t('common:text.signup_field_contract_number')}
              {...register("contract")}
              error={!!(errors.contract)}
              style={{ width: "100%" }}
            />
            {!!(errors.contract?.message) &&
              <FormHelperText error={true}>
                {errors.contract.message}
              </FormHelperText>
            }
          </Grid>

          {Settings.signUp?.invoiceNumberField && (
            <Grid item xs={12}>
              <TextField
                label={t('common:text.signup_field_invoice_number')}
                {...register("invoiceNumber")}
                error={!!(errors.invoiceNumber)}
                style={{ width: "100%" }}
              />
              {!!(errors.invoiceNumber?.message) &&
                <FormHelperText error={true}>
                  {errors.invoiceNumber.message}
                </FormHelperText>
              }
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              label={t('common:text.signup_field_email')}
              {...register("email")}
              error={!!(errors.email)}
              style={{ width: "100%" }}
            />
            {!!(errors.email?.message) &&
              <FormHelperText error={true}>
                {errors.email.message}
              </FormHelperText>
            }
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t('common:text.signup_field_email_repeat')}
              {...register("email2")}
              error={!!(errors.email2)}
              style={{ width: "100%" }}
            />
            {!!(errors.email2?.message) &&
              <FormHelperText error={true}>
                {errors.email2.message}
              </FormHelperText>
            }
          </Grid>

          {Settings.signUp?.phoneField && (
            <Grid item xs={12}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <NoFlagMuiTelInputStyled
                    inputProps={{ maxLength: 12 }}
                    disableDropdown={true}
                    label={t('common:text.signup_field_phone')}
                    forceCallingCode={true}
                    defaultCountry="ES"
                    helperText={errors.phone?.message}
                    fullWidth={true}
                    error={!!errors.phone?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}
        
          <Grid item xs={12}>
            <Controller
              name="acceptConditionsAndGdpr"
              control={control}
              render={({field}) => <FormControlLabel
                control={<Checkbox 
                  {...field}
                  checked={field.value}
                  />} 
                label={
                  <RichHTML html={t("common:text.contractation_ov_gdpr_accept", {
                    linkOV: Settings.links.conditions ?? '',
                    linkGDPR: Settings.links.gdpr ?? ''
                  })}
                  />
                } 
              />}
            />
            {!!(errors.acceptConditionsAndGdpr?.message) &&
              <FormHelperText error={true}>
                {errors.acceptConditionsAndGdpr.message}
              </FormHelperText>
            }
          </Grid>
      
          <Grid item xs={12}>
            <Button
              color={'primary'}
              variant={'contained'}
              type="submit"
              fullWidth
            >
              {t('common:text.signup_button_activate')}
            </Button>
          </Grid>

          <Grid item xs={12} sx={{mt: 3}} justifyContent="center" display="flex">
            {t('common:text.signup_text_info_already_registered')}
            <Link onClick={handleOnLoginNav} underline="hover" sx={{ml: 2}} href="#">
              {t('common:text.login_view_button_send')}
            </Link>
          </Grid>
        
        </Grid>
      </form>
    </Box>
  ) : (
    <div>
      <RichContent content={successBody || ""} />
    </div>
  );
}

export default SignUp;
